<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span>
              标的名称：
              <el-input
                class="ipt_width project_width"
                v-model="parameter.bidName"
                placeholder="请输入标的名称"
              >
              </el-input>
            </span>
            <div class="search_center">
              <span>投标日期范围：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="投标日期（始）"
                end-placeholder="投标日期（末）"
              >
              </el-date-picker>
            </div>
            <span>
              投标状态：
              <Dictionary
                v-model="parameter.bidStatus"
                class="ipt_width"
                code="BID_STATUS"
                placeholder="请选择投标状态"
            /></span>
            <span>
              保证金状态：
              <el-select
                v-model="parameter.depositStatus"
                multiple
                placeholder="请选择保证金转态"
                class="ipt_width"
                @change="onDepositStatusChange"
              >
                <el-option
                  v-for="item in dictData.depositStatus"
                  :key="item.dictVal"
                  :label="item.dictName"
                  :value="item.dictVal"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </span>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset"> 清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="warning" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <div class="colorExplain">
            状态颜色说明：
            <span>已关联费用 <i style="background: #d4f5fd"></i></span>
          </div>
          <!-- 投标总金额：更改字段 -->
          <el-descriptions class="margin-top" :column="1" border>
            <el-descriptions-item label-class-name="labelClassName">
              <template slot="label">
                <span style="color: #000; font-size: 16px">中标总金额</span>
              </template>
              <span style="color: red">{{ sumCost > 0 ? sumCost : 0 | applyAmount }}</span>
            </el-descriptions-item>
          </el-descriptions>

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            :row-class-name="tableRowClassName"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="month"
              width="70"
              :show-overflow-tooltip="false"
              label="月份"
            ></el-table-column> -->

            <el-table-column
              align="center"
              prop="bidName"
              min-width="280"
              :show-overflow-tooltip="false"
              label="标的名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="bidType"
              width="100"
              :show-overflow-tooltip="false"
              label="标的类型"
            >
              <template slot-scope="scope">
                {{ scope.row.bidType | dict(dictData.bidType) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="70"
              :show-overflow-tooltip="false"
              prop="bidHostEscort"
              label="是否重点关注"
            >
              <template slot-scope="scope">
                {{ scope.row.bidHostEscort == 'IS_FLAG' ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="140"
              sortable
              prop="bidOpeningTime"
              label="开标时间"
            >
              <template slot-scope="scope">
                {{ scope.row.bidOpeningTime | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="bidOwnerUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="bidAgency"
              label="代理招标单位"
            ></el-table-column>
            <el-table-column
              align="center"
              width="140"
              sortable
              :show-overflow-tooltip="false"
              prop="bidWinningAmount"
              label="中标金额(元)"
            >
              <template slot-scope="scope">
                {{ scope.row.bidWinningAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="160"
              :show-overflow-tooltip="false"
              prop="bidRemark"
              label="备注"
            ></el-table-column>
            <el-table-column
              align="center"
              width="110"
              :show-overflow-tooltip="false"
              prop="createName"
              label="创建人"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="bidStatus"
              label="状态"
              sortable
              width="100"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.bidStatus | dict(dictData.bidStatus) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="depositStatus"
              label="保证金状态"
              sortable
              width="120"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.depositStatus | dict(dictData.depositStatus) }}
              </template>
            </el-table-column>
            <el-table-column prop="title" align="center" fixed="right" label="操作" width="160">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="text_Edit_Bgc"
                  size="small"
                  @click="edit(scope.row)"
                  v-if="canEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  class="text_Details_Bgc"
                  size="small"
                  @click="detail(scope.row)"
                  >详情</el-button
                >
                <el-popover placement="bottom" width="220" trigger="hover">
                  <el-button
                    type="text"
                    class="text_Edit_Bgc"
                    size="small"
                    v-if="scope.row.isShowUpdate"
                    @click="bidEdit(scope.row)"
                    >保证金修改</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    class="text_Remove_Bgc"
                    @click.stop="delFn(scope.row.id)"
                    v-if="canDelete(scope.row)"
                    >删除</el-button
                  >

                  <el-button
                    type="text"
                    class="text_Miantan_Bgc"
                    size="small"
                    @click="winBid(scope.row)"
                    v-if="canEdit(scope.row)"
                    >是否中标</el-button
                  >
                  <el-button
                    type="text"
                    class="text_RESET_PASSWORD_Bgc"
                    size="small"
                    @click="addMemberDialogFn(scope.row)"
                    v-if="canEdit(scope.row)"
                    >人员分配</el-button
                  >
                  <span slot="reference" class="more">更多</span>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 是否中标弹框 -->
    <WinBidDialog
      :isWinBidDialogShow.sync="isWinBidDialogShow"
      :detailData="winResultData"
      @winBidSave="onWinBidSaveFn"
      :loading="loading"
    ></WinBidDialog>

    <!-- 详情 -->
    <Detail :data="rowData" :isDetailShow.sync="isDetailShow" v-if="isDetailShow"></Detail>

    <!-- 人员分配 -->
    <EditDialog
      width="70%"
      :isReturn="true"
      @closeFn="memberDiaLog = false"
      ref="IframeDlg"
      :isShow.sync="memberDiaLog"
    >
      <div slot="title" class="title">人员分配</div>
      <div slot="content" class="content">
        <Assign ref="Assign" :editType="'add'" :options="assignInfo" />
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" @click="addMemberFn">保存</el-button>
        <el-button type="info" @click="memberDiaLog = false">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    WinBidDialog: () => import('@/components/bid/WinBidDialog.vue'),
    Detail: () => import('@/views/publicView/bid/BidDetail.vue'),
    Assign: () => import('@/views/publicView/bid/Assign.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        bidName: null,
        bidStatus: null,
        depositStatus: null,
      },
      pageSize: 0,
      condition: {
        title: '',
      },
      form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      showEditDialog2: false,
      dictData: {
        bidStatus: [],
        depositStatus: [],
        bidType: [],
      },
      businessList: [],
      selectedList: [],

      rowData: {}, //当前点击的行数据
      isWinBidDialogShow: false, //是否中标弹框
      createdDate: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },

      isDetailShow: false, //详情

      projectAmount: 0, //todo
      memberDiaLog: false, //添加人员
      bidManagementId: null, //当前单据id
      assignInfo: {}, //详情信息
      sumCost: 0,
      routeParams: this.$route.params,
      winResultData: {},
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    canEdit() {
      return function (row) {
        if (this.permission(['BAIL_UPDATE'])) {
          // 财务不可编辑
          return false
        } else {
          return true
        }
      }
    },

    canDelete() {
      return function (row) {
        // if (row.createBy == this.userInfo.staffId) {
        //   return true
        // } else {
        //   return false
        // }
        if (row.operatingButton.includes('DELETE')) {
          return true
        } else {
          return false
        }
      }
    },
  },
  filters: {
    applyAmount(n) {
      return Number(n).toLocaleString()
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.$api.dict
      .listSysDictData('BID_STATUS', true)
      .then(res => {
        this.dictData.bidStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('DEPOSIT_STATUS', true)
      .then(res => {
        this.dictData.depositStatus = res.data
        this.dictData.depositStatus.forEach(item => (item.disabled = false))
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BID_TYPE', true)
      .then(res => {
        this.dictData.bidType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },

    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
    $route: {
      deep: true,
      immediate: true,
      handler: function (val) {
        // if (val.query && val.query.data) {
        //   const item = JSON.parse(val.query.data)
        //   if (item) {
        //     this.bidEdit(item)
        //     // 获取当前页面的 URL
        //     let currentUrl = window.location.href
        //     // 清除查询参数部分
        //     let cleanUrl = currentUrl.split('?')[0]
        //     // 修改浏览器的历史记录，但不会触发页面刷新
        //     window.history.replaceState({}, document.title, cleanUrl)
        //   }
        // }
        if (val.name === 'QBTBLB' && val.params && val.params.data) {
          const item = val.params.data
          this.bidEdit(item)
          // 清空路由中的params对象
          this.routeParams = {}
        }
      },
    },
  },
  methods: {
    async addMemberDialogFn(row) {
      this.bidManagementId = row.id
      const { data } = await this.$api.bid.selectDetailBid(row.id)
      if (data) {
        this.assignInfo = data.deepClone()
        this.memberDiaLog = true
      }
    },
    // 添加人员
    async addMemberFn() {
      if (this.$refs.Assign) {
        let bidMembers = this.$refs.Assign.bidMembers
        const deleteBidMemberList = this.$refs.Assign.deleteBidMemberList
        const arr = bidMembers.flatMap(v => v.MemberList)
        arr.forEach(v => (v.bidManagementId = this.bidManagementId))
        const obj = {
          bidMembers: arr,
          deleteBidMemberList: deleteBidMemberList,
        }
        await this.$api.bid.addMember(obj)
        this.$message.success('操作成功')
        this.memberDiaLog = false
      }
    },
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },

    // 获取所有人员
    getSelectedList() {
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.selectedList = res?.data.deepClone()
          this.$store.commit({
            type: 'business/SET_ALL_USER_LIST',
            allUserList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    delFn(id) {
      this.$confirm('此操作将删除该投标信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true
          this.$api.bid
            .deleteBid(id)
            .then(res => {
              this.loading = false
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    getData() {
      this.loading = true
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }

      this.$api.bid
        .getBidList(this.parameter)
        .then(res => {
          this.loading = false
          if (!res.data) {
            this.tableData = []
            this.parameter.total = 0
          } else {
            this.tableData = res.data.records
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })

      this.$api.bid
        .getSumCost(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data) {
            this.sumCost = res.data
          } else {
            this.sumCost = 0
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    tableRowClassName({ row, index }) {
      if (row.isRelation) {
        return 'relation'
      }
      return ''
    },

    re() {
      this.getData()
      this.isDetailShow = false
    },
    handleReset() {
      this.parameter = {
        pageNow: 1,
        pageSize: this.userInfo.pageSizeLog,
        bidStatus: null,
        bidName: null,
        total: 0,
      }
      this.createdDate = []
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 点击是否中标按钮
    async winBid(row) {
      this.loading = true
      const { data } = await this.$api.bid.selectDetailBid(row.id)
      this.loading = false
      if (data) {
        this.winResultData = {
          fkId: data.id,
          isWin: data.bidStatus,
          fileList: data.bidManagementFiles,
        }
        this.isWinBidDialogShow = true
      }
    },

    async onWinBidSaveFn(data) {
      let req = {
        bidManagementInfoReq: {
          id: data.fkId,
          bidStatus: '',
        },
        bidManagementFileAddList: data.fileList,
        bidManagementFileDeleteList: data.deleteFileList,
      }
      if (data.isWin === '') {
        req.bidManagementInfoReq.bidStatus = 'bidding'
      } else if (data.isWin === 'loser') {
        req.bidManagementInfoReq.bidStatus = 'loser'
      } else if (data.isWin === 'winner') {
        req.bidManagementInfoReq.bidStatus = 'winner'
      }
      this.loading = true
      const res = await this.$api.bid.addBid(req)
      this.loading = false
      this.isWinBidDialogShow = false
      this.winResultData = {}
      this.$message.success('操作成功！')
      this.getData()
    },

    //点击新增
    add() {
      this.rowData = {}
      this.rowData.editType = 'add'
      this.isDetailShow = true
    },

    // 点击保证金修改
    bidEdit(row) {
      this.rowData = row
      this.rowData.editType = 'bidEdit'
      this.isDetailShow = true
    },
    // 点击编辑
    edit(row) {
      this.rowData = row
      this.rowData.editType = 'edit'
      this.rowData.isBidLog = true
      this.isDetailShow = true
    },
    // 点击详情
    detail(row) {
      this.rowData = row
      this.rowData.editType = 'detail'
      this.rowData.isBidLog = true
      this.isDetailShow = true
    },

    // 点击认领
    claim(row) {
      this.rowData = row
      this.rowData.editType = 'claim'
      this.isDetailShow = true
    },

    onDepositStatusChange(e) {
      if (e.length == 0) {
        this.dictData.depositStatus.forEach(item => (item.disabled = false))
        this.$forceUpdate()
      }
      e.forEach(eItem => {
        if (eItem == 'zan_wu_bao_zheng_jin') {
          // 如果选择暂无
          this.parameter.depositStatus = ['zan_wu_bao_zheng_jin']
          this.dictData.depositStatus.forEach(item => (item.disabled = true))
          let find = this.dictData.depositStatus.find(item => item.dictVal == eItem)
          find.disabled = false
          this.$forceUpdate()
        }
        // if (eItem == 'bu_fen_tui_hui' || eItem == 'wei_tui_hui') {
        //   // 如果选择部分退回和未退回
        //   this.dictData.depositStatus.forEach(item => (item.disabled = true))
        //   let find = this.dictData.depositStatus.find(
        //     item => item.dictVal == 'bu_fen_tui_hui' || item.dictVal == 'wei_tui_hui'
        //   )
        //   find.disabled = false
        //   this.$forceUpdate()
        // } else {
        //   //选择其余选项则禁用出自身外所有
        //   this.dictData.depositStatus.forEach(item => (item.disabled = true))
        //   let find = this.dictData.depositStatus.find(item => item.dictVal == eItem)
        //   find.disabled = false
        //   this.$forceUpdate()
        // }
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
#costDialog {
  /deep/ .el-table th {
    background: #ddeeff;
  }
  /deep/ .undefined {
    border-bottom: 0px;
  }
  /deep/.el-table__cell {
    border-left: 1px solid #ebeef5 !important;
  }
  .cost-dialog-title {
    margin-bottom: 10px;
  }
}

.empty-detail {
  height: 444px;
  color: grey;
  font-size: 14px;
  text-align: center;
  position: relative;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

::v-deep .highlight {
  background: rgb(241, 212, 158) !important;
}

.below-content {
  display: flex;
  justify-content: space-between;
}

.colorExplain {
  color: black;
  font-size: 14px;
  span {
    margin-right: 25px;
    i {
      display: inline-block;
      width: 25px;
      height: 10px;
    }
  }
}

/deep/.labelClassName {
  width: 150px;
}
/deep/ .mainList_content > .mainList_content_sub > .el-table {
  height: calc(100% - 122px);
}

// 移除hover时高亮效果
/deep/.el-table__body .el-table__row.hover-row td {
  background-color: transparent;
}
/deep/.el-table tbody tr:hover > td {
  background-color: transparent !important;
}
/deep/.el-table__fixed-right tbody tr:hover > td {
  background-color: transparent !important;
}

/deep/.el-table .relation {
  //已关联商
  background: #d4f5fd;
}
/deep/ .more {
  background-color: #aacdee;
  color: #fff;
  padding: 5px 5px;
  margin-left: 8px;
}
.text_Edit_Bgc {
  background-color: #e6a23c;
  color: #fff;
  padding: 5px 5px;
}
.text_Miantan_Bgc {
  background-color: #6ace61c2;
  color: #fff;
  padding: 5px 5px;
}
.text_RESET_PASSWORD_Bgc {
  background-color: #61becec2;
  color: #fff;
  padding: 5px 5px;
}
.text_Remove_Bgc {
  background-color: #f78888;
  color: #fff;
  padding: 5px 5px;
}
</style>
